.slate-editor__material-icons__icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  vertical-align: bottom;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

.slate-editor {
  margin: 24px 0;
  padding: 24px;
  background-color: white;
}

.slate-editor__toolbar {
  top: 0;
  z-index: 1;
  position: sticky;
  background-color: white;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.slate-editor__toolbarButton {
  cursor: pointer;
  color: #253e50;
  background-color: transparent;
  border: unset;
  padding: unset;
  height: unset;
  padding: 5px;
}

.slate-editor__toolbarButton:hover {
  background-color: #ccc;
}

.slate-editor__toolbarButton--active {
  background: #ddd;
}

.slate-editor__modalCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
}

.slate-editor__modalCloseButton--hidden {
  display: none;
}

.slate-editor__modalInput {
  height: 36px;
  margin: 14px 10px 0 0;
}

.slate-editor__searchResult:not(:first-of-type) {
  margin-top: 24px;
}

.slate-editor__searchResultButton {
  cursor: pointer;
}

.slate-editor__nonEditable {
  border: dashed 1px rgb(144, 164, 174);
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(207, 216, 220, 0.2);
  cursor: not-allowed;
  user-select: none;
}

.slate-editor--hidden {
  display: none;
}

.slate-editor__toolbarButtonPopupWrapper {
  position: relative;
}

.slate-editor__toolbarButtonPopup {
  z-index: 1;
  position: absolute;
  left: 0;
}

.slate-editor__toolbarButtonPopupList {
  list-style: none;
  padding-inline-start: initial !important;
  background-color: #eee;
  border-radius: 3px;
}

.slate-editor__toolbarButtonPopupListAnchor {
  color: #000 !important;
}

.slate-editor__toolbarButtonPopupListAnchor:hover {
  text-decoration: none;
}

.slate-editor__toolbarButtonPopupListItem {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.slate-editor__toolbarButtonPopupListItem:hover {
  background-color: #ddd;
}

.slate-editor__error {
  align-items: center;
  background: lightcoral;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
}

.slate-editor__errorButton {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.slate-editor__autoSizeTextArea {
  border: 0;
  box-shadow: none;
  outline: none;
  padding: 0;
  resize: none;
  width: 100%;
}

.slate-editor__HtmlEditor {
  font-family: "Courier New", Courier, monospace;
}

.slate-editor__toolbarButtonGroupContainer {
  display: flex;
}

.slate-editor__toolbarButtonGroup {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding-left: 3px;
}

.slate-editor__specialCharactersTr {
  height: 20px;
}

.slate-editor__specialCharactersTd {
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
}

.slate-editor__specialCharactersTd:hover {
  background-color: #ccc !important;
}

.slate-editor__specialCharactersMainContainer {
  display: flex;
  font: 17.6px/24px "Publico text", serif;
}

.slate-editor__specialCharactersButton {
  cursor: pointer;
  color: #253e50;
  background-color: transparent !important;
  font: 17.6px/24px "Publico text", serif !important;
  border: unset;
  padding: unset;
  height: 100%;
  width: 100%;
  padding: unset;
  text-transform: unset;
}

.slate-editor__specialCharactersInfoContainer {
  margin-left: 12px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.slate-editor__specialCharactersCharacterContainer {
  text-align: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.slate-editor__specialCharactersCharacterContainer--bordered {
  border: 1px solid #ccc;
}

.slate-editor__specialCharactersHoveredCharacter {
  font-size: 80px;
  line-height: 80px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slate-editor__specialCharactersRepresentationDescription {
  font-size: 10px;
}

.slate-editor__specialCharactersRepresentationOther {
  font-size: 16px;
  font-weight: bold;
}

.slate-editor__iframe {
  border: none;
  width: 100%;
  height: 533px;
}

.slate-editor__info-footer {
  border-top: 1px solid #eee;
}

.slate-editor__char-counter {
  font-size: 14px;
  color: grey;
}

.slate-editor__modal {
  width: min-content;
  height: min-content;
  max-height: 80%;
  margin: 0 auto;
  min-width: 50%;
  background-color: #f3f4f4;
  position: absolute;
  inset: 40px;
  border: 1px solid #ccc;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  padding: 20px
}

@media (min-width: 1250px) {
  .slate-editor__modal {
    min-width: 30%;
  }
}
