html {
  /* Title fonts changed to normal line-height to avoid vertical scrollbar
   * after turning off wrapping
   */
  --title-large: 600 50px "Publico text", serif;
  --title: 600 28px "Publico text", serif;
  --subtitle-large: 600 22px/26px "Publico text", serif;
  --subtitle: bold 19px/24px "Publico text", serif;
  --prioritized: normal 19px/24px "Publico text", serif;
  --body: normal 16px/24px "Publico text", serif;
  --small-body: normal 14.5px/22px "Publico text", serif;
  --category: 600 14px/20px "Work Sans", sans-serif;
  --tagline: bold 13px/20px "Work Sans", sans-serif;
  --meta: normal 15px/22px "Work Sans", sans-serif;
  --timestamp: normal 13px/20px "Work Sans", sans-serif;

  /* Font variants not part of the base "palette" from Fossland+Foss */
  --meta-small: normal 13px/20px "Work Sans", sans-serif;
  --logo-font: normal 17px/24px "Gotham", sans-serif;
  --encyclopedia-font: bold 16px/20px "Work Sans", sans-serif;
  --article-subheading: bold 22px/26px "Publico text", serif;
  --article-subsubheading: var(--prioritized);
  --meta-bold: bold 15px/22px "Work Sans", sans-serif;
  --stats-font: bold 22px/26px "Work Sans", sans-serif;
  --home-link-font: normal 16px/19px "Work Sans", sans-serif;
  --numeric: oldstyle-nums proportional-nums;
}

.theme-lille {
  --title: 600 28px "Guardian Sans", sans-serif;
  --subtitle-large: 600 22px/140% "Guardian Sans", sans-serif;
  --subtitle: 600 20px/140% "Guardian Sans", sans-serif;
  --article-subheading: 600 24px/140% "Guardian Sans", sans-serif;
  --article-subsubheading: normal 23px/140% "Guardian Sans", sans-serif;
  --prioritized: normal 20px/140% "Guardian Sans", sans-serif;
  --large-body: normal 18px/140% "Guardian Sans", sans-serif;
  --body: normal 16px/140% "Guardian Sans", sans-serif;
  --small-body: normal 15px/140% "Guardian Sans", sans-serif;
  --numeric: normal;
}

.theme-lex {
  --title-large: 500 32px "Inter", sans-serif;
  --title: 500 28px "Inter", sans-serif;
  --subtitle-large: 500 22px/26px "Inter", sans-serif;
  --subtitle: 500 19px/24px "Inter", sans-serif;
  --category: 600 14px/20px "Inter", sans-serif;
  --tagline: bold 13px/20px "Inter", sans-serif;
  --meta: normal 15px/22px "Inter", sans-serif;
  --timestamp: normal 13px/20px "Inter", sans-serif;
  --meta-small: normal 14px/20px "Inter", sans-serif;
  --encyclopedia-font: bold 16px/20px "Inter", sans-serif;
  --article-subheading: 700 22px/26px "Inter", sans-serif;
  --article-subsubheading: 600 19px/24px "Inter", sans-serif;
  --meta-bold: bold 15px/22px "Inter", sans-serif;
  --stats-font: bold 22px/26px "Inter", sans-serif;
  --home-link-font: normal 16px/19px "Inter", sans-serif;
  /* From Lex redesign */
  --front-subheading: 600 20px/130% "Inter", sans-serif;
  --front-large-link: normal 22px/130% "Inter", sans-serif;
  --front-preamble: normal 16px/150% "Publico text", serif;
  --search-font: normal 16px/24px "Inter", sans-serif; /* >=16px to avoid zoom on focus on mobile */
  --small-category: 500 12px "Inter", sans-serif;
  --font-footer-welcome: bold 20px/28px "Inter", sans-serif;
  --font-footer-intro: normal 20px/28px "Publico text", serif;
  --font-footer-details: normal 16px/1.8 "Inter", sans-serif;
  --font-banner-title: normal 32px/1.1 "Publico text", serif;
  --font-banner-intro: normal 20px/26px "Inter", sans-serif;
}

@media (--medium-up) {
  html {
    --title-large: 600 50px "Publico text", serif;
    --title: 600 36px "Publico text", serif;
    --subtitle-large: 600 24px/28px "Publico text", serif;

    /* Font variants not part of the base "palette" from Fossland+Foss */
    --logo-font: normal 19px/24px "Gotham", sans-serif;
  }

  .theme-lille {
    --title: 600 36px "Guardian Sans", sans-serif;
    --subtitle-large: 600 24px/140% "Guardian Sans", sans-serif;
  }

  .theme-lex {
    --title-large: 500 36px "Inter", sans-serif;
    --title: 500 36px "Inter", sans-serif;
    --subtitle-large: 500 24px/28px "Inter", sans-serif;
    --front-large-link: normal 24px/130% "Inter", sans-serif;
  }
}

@media (--large-up) {
  html {
    --title-large: 600 60px "Publico text", serif;
    --title: 600 40px "Publico text", serif;
    --subtitle-large: 600 30px/36px "Publico text", serif;
    --subtitle: bold 22px/28px "Publico text", serif;
    --prioritized: normal 22px/28px "Publico text", serif;
    --body: normal 18px/27px "Publico text", serif;
    --small-body: normal 16px/24px "Publico text", serif;
    --category: 600 15px/22px "Work Sans", sans-serif;
    --tagline: bold 14px/20px "Work Sans", sans-serif;
    --meta: normal 16px/24px "Work Sans", sans-serif;

    /* Font variants not part of the base "palette" from Fossland+Foss */
    --logo-font: normal 22px/28px "Gotham", sans-serif;
    --encyclopedia-font: bold 20px/22px "Work Sans", sans-serif;
    --article-subheading: bold 26px/30px "Publico text", serif;
    --meta-bold: bold 16px/24px "Work Sans", sans-serif;
    --stats-font: bold 26px/30px "Work Sans", sans-serif;
    --home-link-font: normal 20px/23px "Work Sans", sans-serif;
  }

  .theme-lille {
    --title: 600 60px "Guardian Sans", sans-serif;
    --subtitle-large: 600 30px/140% "Guardian Sans", sans-serif;
    --subtitle: 600 24px/140% "Guardian Sans", sans-serif;
    --article-subheading: 600 32px/140% "Guardian Sans", sans-serif;
    --article-subsubheading: normal 28px/140% "Guardian Sans", sans-serif;
    --prioritized: normal 22px/140% "Guardian Sans", sans-serif;
    --large-body: normal 20px/140% "Guardian Sans", sans-serif;
    --body: normal 18px/140% "Guardian Sans", sans-serif;
    --small-body: normal 16px/140% "Guardian Sans", sans-serif;
  }

  .theme-lex {
    --title-large: 500 40px "Inter", sans-serif;
    --title: 500 40px "Inter", sans-serif;
    --subtitle-large: 500 30px/36px "Inter", sans-serif;
    --subtitle: 500 22px/28px "Inter", sans-serif;
    --article-subheading: 600 24px/30px "Inter", sans-serif;
    --article-subsubheading: 600 20px/26px "Inter", sans-serif;
    --category: 600 15px/22px "Inter", sans-serif;
    --tagline: bold 14px/20px "Inter", sans-serif;
    --meta: normal 16px/24px "Inter", sans-serif;
    --encyclopedia-font: bold 20px/22px "Inter", sans-serif;
    --meta-bold: bold 16px/24px "Inter", sans-serif;
    --stats-font: bold 26px/30px "Inter", sans-serif;
    --home-link-font: normal 20px/23px "Inter", sans-serif;
    --font-banner-title: normal 48px/1.1 "Publico text", serif;
    --font-banner-intro: normal 20px/30px "Inter", sans-serif;
  }
}

@media (--xlarge-up) {
  html {
    --title-large: 600 70px "Publico text", serif;
    --title: 600 50px "Publico text", serif;
    --subtitle-large: 600 40px/46px "Publico text", serif;
    --subtitle: bold 26px/30px "Publico text", serif;
    --prioritized: normal 26px/30px "Publico text", serif;
    --body: normal 20px/30px "Publico text", serif;
    --small-body: normal 17px/26px "Publico text", serif;
    --category: 600 16px/20px "Work Sans", sans-serif;
    --tagline: bold 15px/20px "Work Sans", sans-serif;

    /* Font variants not part of the base "palette" from Fossland+Foss */
    --logo-font: normal 26px/30px "Gotham", sans-serif;
    --encyclopedia-font: bold 22px/24px "Work Sans", sans-serif;
    --article-subheading: bold 32px/38px "Publico text", serif;
    --stats-font: bold 36px/40px "Work Sans", sans-serif;
  }

  .theme-lille {
    --title: 600 70px "Guardian Sans", sans-serif;
    --subtitle-large: 600 40px/140% "Guardian Sans", sans-serif;
    --subtitle: 600 26px/140% "Guardian Sans", sans-serif;
    --article-subheading: 600 36px/140% "Guardian Sans", sans-serif;
    --article-subsubheading: normal 30px/140% "Guardian Sans", sans-serif;
    --prioritized: normal 26px/140% "Guardian Sans", sans-serif;
    --large-body: normal 22px/140% "Guardian Sans", sans-serif;
    --body: normal 20px/140% "Guardian Sans", sans-serif;
    --small-body: normal 17px/140% "Guardian Sans", sans-serif;
  }

  .theme-lex {
    --title-large: normal 60px "Inter", sans-serif;
    --title: 500 50px "Inter", sans-serif;
    --subtitle-large: 500 40px/46px "Inter", sans-serif;
    --subtitle: 500 24px/30px "Inter", sans-serif;
    --category: 600 16px/20px "Inter", sans-serif;
    --tagline: 800 13px/20px "Inter", sans-serif;
    --encyclopedia-font: bold 22px/24px "Inter", sans-serif;
    --article-subheading: 600 28px/38px "Inter", sans-serif;
    --article-subsubheading: 600 22px/30px "Inter", sans-serif;
    --stats-font: bold 36px/40px "Inter", sans-serif;
  }
}
