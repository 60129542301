.l-theme {
  margin-top: var(--spacing1);
  padding: 0 var(--half-gutter);
  min-height: 50vh;
}

.l-theme__notice, 
.l-theme__publishing-tools {
  margin-bottom: var(--spacing1);
}

.l-theme__intro,
.l-theme__section {
  margin-top: var(--spacing2);
}

.theme-lille .l-theme__intro {
  margin-top: 0;
}

.l-theme__publish {
  display: inline;
}

.l-theme__toc {
  margin-top: var(--spacing1);  
}

.l-theme__section + .l-theme__section {
  margin-top: 0;
}

.l-theme__section + .l-theme__section:before {
  content: "";
  display: block;
  border-top: 1px solid;
  margin-bottom: var(--spacing2);
}

.l-theme__section-heading {
  font: var(--article-subheading);
  font-variant-numeric: var(--numeric);
  margin-bottom: .25em;
}

.l-theme__section-lede {
  margin: 0;
}

.l-theme__articles {
  margin-top: var(--spacing1);
}

.l-theme__article,
.l-theme__link-list {
  margin-bottom: var(--spacing2);
}

@media (--medium-up) {
  .l-theme {
    width: var(--grid-width);
    margin-inline: auto;
  }

  .l-theme__notice {
    padding: 0 var(--half-gutter);
  }

  .l-theme__intro,
  .l-theme__publishing-tools,
  .l-theme__section-lede {
    padding: 0 var(--half-gutter);
    width: calc(4 / 6 * 100%);
  }

  .l-theme__toc {
    padding: 0 var(--half-gutter);
    width: calc(2 / 6 * 100%);
  }

  .l-theme__section + .l-theme__section:before {
    margin-inline: var(--half-gutter);
  }

  .l-theme__section-heading {
    padding: 0 var(--half-gutter);
  }

  .l-theme__articles {
    display: flex;
    flex-wrap: wrap;
  }

  .l-theme__article {
    width: calc(2 / 6 * 100%);
    padding: 0 var(--half-gutter);
  }

  .l-theme__link-list {
    padding: 0 var(--half-gutter);
  }
}

@media (--medium-plus-up) {
  .l-theme__publishing-tools,
  .l-theme__section-lede {
    width: calc(6 / 10 * 100%);
  }

  .l-theme__top-content {
    width: calc(10 / 10 * 100%);
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
  }

  .l-theme__toc {
    margin-top: 0;  
    width: calc(2 / 10 * 100%);
  }

  .l-theme__intro {
    margin-top: 0;  
    width: calc(6 / 10 * 100%);
  }

  .l-theme__article {
    width: calc(3 / 10 * 100%);
  }

  .l-theme__articles--five-col .l-theme__article {
    width: calc(2 / 10 * 100%);
  }

  .l-theme__link-list--three-col {
    margin-right: calc(1 / 10 * 100%);
  }
}

@media (--large-up) {
  .l-theme__publishing-tools {
    width: calc(6 / 12 * 100%);
    margin-left: calc(1 / 12 * 100%);
  }

  .l-theme__intro:last-child,
  .l-theme__toc:last-child {
    margin-left: calc(1 / 12* 100%);
  }

  .l-theme__intro {
    width: calc(6 / 12 * 100%);
  }


  .l-theme__toc {
    width: calc(2 / 12 * 100%);
  }

  .l-theme__section-heading {
    margin-left: calc(1 / 12 * 100%);
  }

  .l-theme__section-lede {
    margin-left: calc(1 / 12 * 100%);
    width: calc(6 / 12 * 100%);
  }

  .l-theme__articles {
    margin-inline: calc(1 / 12 * 100%);
  }

  .l-theme__article {
    width: calc(3 / 10 * 100%);
  }

  .l-theme__articles--five-col .l-theme__article {
    width: calc(2 / 10 * 100%);
  }

  .l-theme__link-list--three-col {
    margin-left: calc(1 / 12 * 100%);
    margin-right: calc(2 / 12 * 100%);
  }

  .l-theme__link-list--five-col {
    margin-left: calc(1 / 12 * 100%);
    margin-right: calc(1 / 12 * 100%);
  }
}
