.page-header {
  margin-bottom: var(--spacing1);
  /* Some old Safari versions still don't understand aspect-ratio */
  --image-height-padding: calc(586 / 1544 * 100%);
}

.theme-lille .page-header {
  margin-bottom: 0;
}

.page-header--image {
  position: relative;
  z-index: 0; /* Establish stacking context */
  margin-inline: calc(-1 * var(--half-gutter));
}

.page-header__figure {
  margin: 0;
}

.page-header__image {
  vertical-align: top;
  width: 100%;
  height: auto;
}

.page-header__attribution {
  text-align: right;
  font: var(--meta-small);
}

.page-header__edit-image {
  position: absolute;
  top: 0;
  left: 0;
  /* Position on top of page-header__heading-wrapper, so we stay clickable */
  z-index: 1;
  background: var(--black-haze);
}

.page-header__professions {
  font: var(--category);
}

a.page-header__profession {
  border-bottom-style: none;
}

a.page-header__profession:hover,
a.page-header__profession:focus {
  border-bottom-style: solid;
}

.page-header__profession:link {
  color: var(--alternate-link-color);
  border-bottom-color: var(--alternate-link-underline);
}

@media (--small) {
  .page-header__attribution {
    padding: 0 var(--half-gutter);
  }

  .page-header--image .page-header__heading {
    margin: var(--spacing1) var(--half-gutter) 0;
  }
}

@media (--medium-up) {
  .page-header:not(.page-header--image) {
    padding: 0 var(--half-gutter);
  }

  /* For the case when image is not loaded: avoid abspos
   * .page-header__heading-wrapper overflowing .page-header
   */
  .page-header__image-wrapper {
    position: relative;
    padding-top: var(--image-height-padding);
    background: white;
  }

  .page-header__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .page-header--image .page-header__heading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: var(--image-height-padding);
    width: 100%;
  }

  .theme-lille .page-header__heading-wrapper {
    padding-top: 1rem;
  }

  .theme-lille .page-header--image .page-header__heading-wrapper {
    position: static;
  }

  .page-header--image .page-header__heading {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: var(--half-gutter);
    padding: var(--half-gutter);
    background: rgba(0, 0, 0, 0.5);
    max-height: 80%;
    overflow: auto;
  }

  .page-header--image.page-header--dark-text .page-header__heading {
    background: rgba(255, 255, 255, 0.6);
  }

  .theme-lille .page-header--image .page-header__heading {
    background: transparent;
    padding: 0;
    margin-left: var(--gutter);
    margin-right: var(--gutter);
    position: relative; /* stack on top of the image */
  }
}

@media (--large-up) {
  .page-header--image {
    margin-inline: var(--half-gutter);
  }

  .page-header:not(.page-header--image) {
    width: calc(10 / 12 * 100%);
    margin-left: calc(2 / 12 * 100%);
  }

  .page-header--theme.page-header:not(.page-header--image) {
    margin-left: calc(1 / 12 * 100%);
  }

  .page-header--image .page-header__heading {
    margin-left: calc(2 / 12 * (100% + 2 * var(--half-gutter)) - var(--half-gutter));
    margin-right: calc(1 / 12 * (100% + 2 * var(--half-gutter)) - var(--half-gutter));
  }

  .page-header--theme.page-header--image .page-header__heading {
    margin-left: calc(1 / 12 * (100% + 2 * var(--half-gutter)) - var(--half-gutter));
  }

  .theme-lille .page-header--image .page-header__heading {
    margin-left: calc(2 / 12 * (100% + 2 * var(--half-gutter)));
    margin-right: 0;
  }

  .theme-lille .page-header--theme.page-header--image .page-header__heading {
    margin-left: calc(1 / 12 * (100% + 2 * var(--half-gutter)));
  }
}
